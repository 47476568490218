body, html {
  height: 100%; 
  margin: 0; 
}


body {
  background: url(./assests/p1.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed; 
  position: relative; 
  background-color: #f8f9fa;
  }

body::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: -1;
}

