
.navbar {
  background-color: none;
  border-bottom: 2px solid rgba(29,57,76, .4);
  height: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  padding: 0 5px;
  top: 0;
  z-index: 20;
  transition: background-color 0.3s;
}


.nav-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80px;
  max-width: 1800px;
  position: relative;
}

.nav-logo {
  color: #ffffff;
  align-items: center;
  
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-items: center;
}

.nav-logo img{
  height: 50px;
}

.nav-logo span{
  color: rgba(255, 255, 255, 0.696);
  font-size: 25px;
}

.nav-logo .icon {
  display: inline-block;
  width: 3rem;
  height: 3rem;
  margin-left: 16px;
}

.nav-menu {
  display: flex;
  list-style: none;
  text-align: center;
  margin-right: 2rem;
}

.nav-links {
  color: #fff;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  border-bottom: 3px solid transparent;
  font-size: 20px;
  transition: color 0.3s;
}

.nav-item {
  line-height: 40px;
  margin-right: 1rem;
}

.nav-item:after {
  content: "";
  display: block;
  height: 3px;
  width: 0;
  background: transparent;
  transition: width 0.7s ease, background-color 0.5s ease;
}

.nav-item:hover:after {
  width: 100%;
  background: #1d394c;
}

.nav-item .active {
  color: #ffffff;
}

.nav-icon {
  display: none;
  color: #ffffff;
}

.nav-icon .icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
}

@media screen and (max-width: 960px) {
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: absolute;
    padding: 0 0;
    top: 52px;
    left: -110%;
    opacity: .5;
    transition: all .8s ease;
    top: 100%;    }

  .nav-menu.active {
    background:none;
    left: 0px;
    opacity: 1;
    z-index: 1;
    background-color: #e2e8f0;
    top: 100%;
  }
  .nav-item .active {
    border: none;
    color: #1d394c;
  }
  .nav-links {
    padding: 1.5rem;
    width: 100%;
    display: table;
    color: #1d394c;
  }

  .nav-logo .icon {
    width: 2rem;
    height: 2rem;
  }

  .nav-icon {
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(-100%, -50%);
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    color: #1d394c;
  }

  .nav-item:hover:after {
    width: 103%;
    background: #1d394c;
    
  }
  
}

.navbar.scrolled {
  background-color: #e2e8f0; 
  transition: background-color 0.3s; 
}

.navbar.scrolled .nav-links {
  color: #1d3a4d; 
  transition: color 0.3s;
}